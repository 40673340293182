.App{
  min-width: 100%;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

input[type="text"],
input[type="email"],
input[type="password"]{
      appearance: none;
     -webkit-appearance: none;
     border-radius: 0;
}



.notFound{
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}



h1{
  max-width:100%;
  font-family: 'Montserrat', sans-serif;
  color: #dbba6f;
  font-weight: 400;
}

.pg-title{
  text-align: center;
  max-width: 100%;
  margin-bottom: 1em;
  text-transform: uppercase;
}

video::-webkit-media-controls {
  pointer-events: none;
}

video::-webkit-media-controls-panel {
  pointer-events: none;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button {
  display: none; /* Optionally, you can also hide the play button */
}
