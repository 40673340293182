.Tools{
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.Tools h1{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.Tools .pg-title{
    text-align: center;
    max-width: 100%;
    margin-bottom:1em;
}

.tool-category{
    background-color: rgb(0, 0, 0, .05);
}

.tool-category a{
    text-decoration: none;
    max-width: 100%;
    color:#dbba6f;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;

}

.tool-category a:hover{
    color:#292929
}

.ext{
    font-size: .75em;
}

.tool-category h1{
    text-transform: uppercase;
    padding-left:initial;
    margin:0;
    text-align: center;
}

.tool-category{
    margin-top:2em;
    margin-bottom:5em;
    padding:1em;
}

.tool-info{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.tool-category ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
    width:100%;
}

.tool-category li{
    width: 25%;
    box-sizing: border-box;
    min-height:6em;
    margin:1em; 
}

.tool-category li > p, li > h2{
    display: flex;
    justify-content: center;
    text-wrap:balance;
}

.tool-category li > p{
    height:8em;
    padding:1em;
    text-align: center;
}

.tool-category li > h2{
    width:100%;
}

.tool-info li{
    display: flex;
    flex-direction: column;
    align-items: center;
}

p{
    width:100%;
  
}

.pages{
    display: flex;
    justify-content: center;
    flex-direction: row;
    z-index: 2;
}

.page-selector{
    margin-left:1em;
    margin-right: 1em;
    z-index: 2;
    border-radius: 100%;      
}




@media (max-width: 768px) {
    .tool-category ul {
      flex-direction: column; 
      align-items: center; 
      min-width:100%;
    }
    
    .tool-category li {
      min-width: 100%; 
      margin-left:0;
    }
    .tool-category li > p {
      
        padding-top: 1em;
    }
  


}

