.nav{
    min-width: 100%;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    min-height:10em;
    margin-bottom: 3.5em;  
}
.nav-content{
    margin:.5em;
    max-width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-height:8em;
    padding-right: 2em;
}
.nav-content a{
    text-decoration: none;
    max-width: 100%;
    margin:.5em;
    color:#dbba6f;
    font-size: 1.5em;
    text-align: center;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.nav-link{
    display: flex;
    justify-content: left;
    align-items: center;
    max-height:8em;
    max-width: 50%;
    margin: 1em;
    margin-left:2.5em;
}

.nav-link img{
    width:15em;
    max-width: 100%;
    transition: all 0.3s ease-in-out;
}   

.nav-wave{
    position:absolute;
    z-index: -1;
    min-width: 100%;
    line-height: 0;
    transform: scaleY(-1);
    overflow: hidden;
   

}
.nav-wave svg{
    position: relative;
    display: block;
    min-width: calc(390% + 1.3px);
    max-width: 100%;
    height: 18em;
    max-height: 18em;
    z-index: -1;
}
.nav-wave.wave-2{
    top:1em;
}
.shape-fill-2{
    fill:#dbba6f
}
.shape-fill{
    fill: #292929;
}

.nav-content a:hover{
    color:#cbcbcb 
}

.nav-btn:hover{
    color:#cbcbcb
}
.nav-btn{
    cursor:pointer;
    background:transparent;
    border: none;
    outline: none;
    color: #dbba6f;
    display:none;
    visibility: hidden;
    opacity: 0;

}
.nav-content-mobile{
    display: none;
    visibility: hidden;
    opacity: 0;
}

.dropdown{
    max-height: 20%;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #292929;
}

.dropdown-content a{
    width: 100%;
    text-align: left;
    margin:0;
    padding:5px;
}    

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: flex;
    text-align: left;
    flex-direction: column;
}

@media (max-width:1024px){
    .nav-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: visible;
        opacity: 1;
  
    }
    .nav-bars{
        max-width: 10em;
        max-height: 8em;
        margin-right: 2em;
    }
    .nav-link{
        min-width: 30%;
        max-width: 35%;
        margin-right:0;
    }
    .nav-close-btn{
        position: fixed;
        justify-content: right;
        top:.5em;
        right:.5em;
    }
    .nav-content{
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100vw;
        min-height: 100%;
        background-color: #292929;
        transform: translateX(200vw);
        font-size: 2rem;
        margin: 0;
        overflow-y: scroll;
        padding:0;
        z-index: 3;
    }
    .nav-content a{
        text-decoration: none;
        padding:0;
        margin:0px;
        text-align: center;
    }
    .show_nav{
        transition: .2s;
        transform: translateY(0vh);
    }
}

@media (max-width:700px){
    .nav-link{
        min-width: 35%;
        max-width: 35%;
        margin-left: 1.5em;
    }
    .nav-bars{
        min-width: 10%;
        margin-right: 2em;
    }
}


@media (max-width:424px){
    .wave-2{
        top:.5em;
    }
    .wave-1{
        top:-.5em;
    }
    .nav-bars{
        
        margin-right: 1em;
        height:6em;
    }
    .nav-link{
        height:7.5em;
    }
    .nav-wave svg{
        min-width: calc(700% + 1.3px);
    }

}

@media (max-width:300px){
    .nav-wave svg{
        min-width: calc(600% + 1.3px);
    }
    .wave-2{
        top:.2em;
    }
    .wave-1{
        top:-.7em;
    }
}

