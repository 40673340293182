.Add-User{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6em;
}

.Add-User h1{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.Add-User .pg-title{
    text-align: center;
    max-width: 100%;
    margin-bottom: 1em;
}


.Add-User form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.add-user-form  h2, .add-user-form input, .add-user-form button{
    -webkit-appearance: none;
    border-radius: 0;
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    color: #292929;
}

.add-user-form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1rem;
    width:100%;
    
}

.add-user-form input, button, textarea {
  font-family: 'Nunito', sans-serif;
  -webkit-appearance: none;
  border-radius: 0;
  appearance: none;
  font-size: 1.5em;
  padding: 0.25rem;

}

.add-user-form input{
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    border-bottom: .1em solid #292929;
    margin:1em;
    width:30%;
}

.add-user-form button {
  display: flex;
  align-items: center;
  margin:1em;
  padding: 0.5rem;
  background-color: #292929;
  text-align: center;
  color:#dbba6f;
  border: none;
  border-radius: .5em;
  height:1.8em;
}

.add-user-form button:hover{
    color:#292929;
    background-color: #dbba6f;
}

.add-user-form .delete-btn{
    background-color: red;
    color:#FFFFFF;
}

.add-user-form .delete-btn:hover{
    color:red;
    background-color: gray;
}


.btn-wrapper{
    display: flex;
    justify-content: center;
}

.add-user-form h3{
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.add-user-form p{
    text-align: center;
}


input[type="checkbox"], input[type="file"]{
    all:unset;
    -webkit-appearance: checkbox;
    appearance: checkbox;
    transform: scale(1.5);
    margin: 1em;
}

input[type="file"]{
    text-align: center;
    margin: 1em 0;
}


