.Loading{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5em;
    color: #d0ad50;
}

