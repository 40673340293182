.Events{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 1em;
    
}

.Events h1{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.Events h1 > a{
    text-decoration: none;
    color: #dbba6f;
}

.Events h1 > a:visited{
    color: #dbba6f;
}



.Events h1 > a:hover{
    color:#292929;
}

.Events .pg-title{
    text-align: center;
    max-width: 100%;
    margin-bottom: 2em;
}

.link-calendar{
    margin-top: 1em;
    margin-bottom: 0em;
}

.event-block{
    display: flex;
    justify-content: center;
    min-height:25em;
    box-sizing: border-box;
    flex-direction: row-reverse;
    margin-bottom: 2em;
}

.event-block.odd {
    flex-direction: row; /* Reverse the order for odd blocks */
}

.block-img{ 
    display: flex;
    justify-content: center;
    max-width:35%;
    
}

.block-img img{
    display: block;
    max-width: 100%;
    max-height: 100%; 
    width: auto;
    height: auto;
    object-fit: cover;
}

.block-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    box-sizing: border-box;
    background-color: rgb(0, 0, 0, .05);
}

.block-content li > p{
   width:70%
}

.block-content ul{
    list-style: none;
}

.block-content h2{
    margin-bottom: 1em;
}

.block-content li{
    display: flex;
    justify-content: center;
}


@media  (max-width:1024px){

    .event-block, .event-block.odd{
        flex-direction: column;
       
    }


    .block-content, .block-img{
        width: 100%;
        max-width: 100%;
    }

    .block-img{
        justify-content: center;
    }
    

    .block-content{
        order:-1;
        padding:1em;
    }

    .block-img{
        margin-bottom: 1em;
    }

    .link-calendar{
        width:80%;
    }
}

@media (max-width:400px){

    .block-content li{
        justify-content: center;
    }

    .block-content li > h2{
        width: 80%;
        text-align: center;
        margin-bottom: 0;
    }

    .block-content li > p{
        display: flex;
        width:75%;
        text-wrap: balance;
        text-align: center;
    }

    .Events{
        margin-bottom: 3em;
    }

}


