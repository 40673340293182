

.dot{
    height: 15px;
    width: 15px;
    background-color: #cbcbcb;
    border-radius: 50%;
    display: inline-block;
}




.dot:hover{
    height: 15px;
    width: 15px;
    background-color: #cbcbcb;
    border-radius: 50%;
    display: inline-block;
}

.clicked .dot{
    background-color:  #dbba6f;
}

.arrow{
    font-size: 1.5em;
    color:#dbba6f;
    margin: 0 1em;
}

.arrow:hover{
    color:#dbba6f
}

.pages{
    display: flex;
    justify-content: center;
    align-items: center;
}