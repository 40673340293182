.Account{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 6em;
}

.Account h1{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.Account .pg-title{
    text-align: center;
    max-width: 100%;
}

.Account h2{
    text-transform: uppercase;
    font-weight: 400;
}



.edit-account{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width:100%;
    margin-top:2em;    
}

.hide{
    visibility: hidden;
    display: none;
}

.edit-account h1{
    text-align: center;
    padding:.2em;
    background-color: #292929;
}

.edit-name, .edit-pwd{
    width:25%;
    border-radius: 25px;
    height:max-content;
    margin: 1em 2em;
    overflow: hidden;
    transition: max-width 0.5s ease-in-out;
}


.edit-name form, .edit-pwd form{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0, .05);
    border-radius: 0% 0% 20px 20px;
}

.edit-name form input, .edit-pwd form input{
    -webkit-appearance: none;
    border-radius: 0;
    appearance: none;
    min-width:50%;
    max-width: 85%;
}

.edit-name input, .edit-pwd input, .submit input{
    border: none;
    outline: none;
    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    border-bottom: .1em solid #292929;
    margin:1em;

}

.instructions{
    max-width:50%;
    color:red;
    padding: .5em;
}

.submit button {
    
    margin-top: 1rem;  
    padding: 0.5rem;
    background-color: #292929;
    text-align: center;
    color:#dbba6f;
    border: none;
    border-radius: .5em;
    height:1.8em;
}
  
.submit button:hover{
    color:#292929;
    background-color: #dbba6f;
}

.submit h3{
    color: red;
    text-align: center;
}

@media (max-width:1023px){
    
    .edit-account{
        flex-direction:column;
        align-items: center;
    
    }

    .edit-name, .edit-pwd{
        min-width: 65%;
    }
    .submit form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .submit button{
        margin-bottom:1em;
    }
}


@media (max-width:300px){

    .Account{
        font-size: .8em;
    }
    .edit-name, .edit-pwd{
        max-width: 75%;
    }

}

/*@media (max-width:600px){
    .edit-name, .edit-pwd{
        width:65%;
    }

}*/