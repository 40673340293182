.contact-link:hover .contact-edit{
    opacity: 50%;
    background-color: rgb(0, 0, 0, .05);

}

.contact-link{
    position: relative;
    text-decoration: none;
    color:black;
}

.Edit-Contact ul{
    list-style-type: none;
}

.contact-img img{
    border-radius: 50%;
    height:15em;
    width:15em;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}


.edit-banner{
    width:100%;
    position:absolute;
    height:100%;
    right:0;
    text-align: right;
    padding: .25em .5em;
    z-index: 1;
    visibility: hidden;
    display: none;
}

.contact-link:hover .edit-banner{
    visibility: visible;
    display: initial;
}

.contact-link:hover img{
    opacity: 75%;
}

.contact-edit{
    padding:1em;
}

.contact-edit li{
    margin:0;
}