.Contacts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:5em;
    font-family: 'Montserrat', sans-serif;
}

.Contacts .pg-title{
    margin-bottom: 1em;
}

.Contacts h1, .contact-name{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.contact-name{
    font-size: 1.5em;
}

.contact-title{
    font-size: 1.2em;
}

.icon{
    margin-right: .5em;
}

.contact-info{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.pagination{
    margin-top: 2em;
}

.contact{
    display: flex;
    margin: 0 0em;
    font-size: 1em;

}

.contact ul{
    list-style:none;
    margin:1em 0;
}

.contact img{
    height:11em;
    width:11em;
    border-radius: 100%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}


.contact li{
    text-align: center;
    margin: 0 1em;
    width: 100%;
}

.contact a{
    text-decoration: none;
    color:black;
    max-width: 100%;
}

.contact a:hover{
    color:#dbba6f
}


.meeting{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top:3em;
    text-align: center;
}

.card-container{
    display: flex;
    flex-direction:row;
    justify-content: center;
    flex-wrap: wrap;
}
.card-title{
    font-size: 1.5em;
}

.card{
    width: 30%;
    padding: 1em;
    margin:1em;
    height:30em;
    background-color: #292929;
    border-radius: 25px;
    border: solid #dbba6f 5px;
    position: relative;
}

.card a{
    text-decoration: none;
}

.card img{
    max-width:18em;
    max-height:13em;
    aspect-ratio: 16/9;
    margin:1em 1em 0 1em;
    object-fit: contain;
   
}
.card-body{
    display: flex;
    justify-content: center;
}
.card .card-text{
    width:20em;
    text-align: left;
    color:#FFFFFF;
    padding:1em;   
}

.card-body ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width:20em;
    text-transform: uppercase; 
    list-style: '\2605';
}

.card-body li::marker{
    color:#dbba6f;
}

.card-body li{
    width:15em;
}

.card-footer{
    position: absolute;
    display: flex;
    bottom:1em;
    left:0;
    width:100%;
    justify-content: center;
}

.card-footer button {
    display: flex;
    align-items: center;
    margin-top: 1rem;  
    padding: 0.5rem;
    background-color: #dbba6f;
    text-align: center;
    color:#292929;
    border: none;
    border-radius: .5em;
    height:1.8em;
}
  
.card-footer button:hover{
    color:#292929;
    background-color: #cbcbcb;
}

@media (max-width:1440px){
    .contact-info{
        width:75%;
    }

}

@media (max-width:1195px){
    .contact-info{
        width:100%;
    }

}
@media (max-width:1024px){

   .card-container{
        min-width:100%;
        flex-direction: column;
    }

    .card{
        width:100%;
        margin: 1em 0;
    }

}

@media (max-width:624px){

    .contact-info{
        width:100%;
    }
    .contact{
        max-width: 50%;
        margin:0;
    }


    .contact li{
        margin:0 .4em;
    }

    .contact img{
        height:10em;
        width:10em;
    }

    .contact-info, .meeting{
        font-size: .75em;
    }


}

@media (max-width:440px){
    
    .contact li > a{
        font-size: .8em;
    }
}   

@media (max-width:300px){
    .Contacts{
        font-size: .5em;
    }

}