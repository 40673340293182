.tool-edit-btn {
    display: flex;
    align-items: center;
    margin:.5em;
    padding: 0.5rem;
    background-color: #dbba6f;
    text-align: center;
    color:#292929;
    border: none;
    border-radius: .5em;
    height:1.8em;
}
  
.tool-edit-btn:hover{
    color:#dbba6f;
    background-color: #292929;
}

.Edit-Tool select {
    display: flex;
    align-items: center;
    margin: 0 .5em; 
    padding: 0.5rem;
    background-color: #dbba6f;
    text-align: center;
    color:#292929;
    border: none;
    border-radius: .5em;
    height:2em;
    font-size: 1em;
    margin:1em;
}

.Edit-Tool label{
    display: flex;
    align-items: center;
}

.Tools h3{
    margin:1em;
    color:green;
    text-align: center;
    text-transform: uppercase;
 
}

