.Footer{
    display:flex;
    flex-wrap: wrap;
    background-color: #292929;
    margin-top: auto;
    border-top: 15px solid #dbba6f;
    color:#dbba6f;
    font-family: 'Montserrat', sans-serif;
    justify-content: space-between;

}
.Footer a, .Footer a:visited{
    color:#cbcbcb;
    text-decoration: none;
}

.Footer a:hover{
    color:#dbba6f;
}

.Footer h3{
    text-align: left;
    width:100%;
}

.Footer .foot-socials a{
    color:#dbba6f;
}

.foot-socials a:hover{
    color:#cbcbcb;
}


.foot-1{
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
    width:100%;
    padding-top: 1em;
}


.foot-socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:2.5em;

}

.foot-socials ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    font-size: 1.5em;
    margin: .5em 0;
}

.foot-socials img{
    width:15em;
    max-width: 100%;
}
.foot-socials li{
    margin:0;
    margin-right:.5em;
}

.foot-goto{
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    margin-right: 2em;
}

.foot-goto h3{
    margin-bottom: .5em;
}

.foot-goto  a{
    text-decoration: none;
}


.foot-contact{
    display: flex;
    flex-direction: column;
}

.foot-contact li{
    list-style: none;
    color:#cbcbcb;
    margin:.5em 0;
}

.foot-2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:100%;
    align-items: center;
    font-size: .8em;
    color: #cbcbcb;
    margin-bottom: 1em;

}

.foot-2 p{
    width: 100%;
    text-align: center;
}

.foot-2 ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.foot-2 li:first-child{
    list-style: none;
}

.foot-2 li{
    margin: .5em 1em .5em 1em;
}

.foot-2 li > a, .foot-2 li > a:visited{
 color:#cbcbcb;
}

.foot-2 li > a:hover{
    color:#dbba6f
}




.foot-other{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.foot-other ul{
    display: flex;
    flex-wrap: wrap;
    background-color: red;
}

.foot-other h3{
    margin-bottom: .5em;

}

@media (max-width:720px){
    .foot-goto{
        visibility: hidden;
        display: none;
    }

    .foot-contact h3{
        visibility: hidden;
        display: none;
    }

    .foot-socials{
        margin-right: 1em;
        margin-left: 0;
    }
}

@media (max-width:520px){
    .foot-2 li{
        margin:1em;
    }

    .foot-2 ul{
        list-style: none;
    }

}
@media (max-width:496px){

    .foot-socials{
        margin:0;
        margin-bottom: 1em;
    }
    .foot-socials ul{
        margin:0;
    }

    .foot-contact{
        margin:0;
    }

    .foot-contact h3{
        width:100%;
        text-align: center;
    }
    .foot-contact li > p{
        width:100%;
        display: flex;
        justify-content: center;
    }

   


}