.View-Users{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6em;
}

.View-Users h1{
    max-width:100%;
    font-family: 'Montserrat', sans-serif;
    color: #dbba6f;
    font-weight: 400;
}

.View-Users .pg-title{
    text-align: center;
    max-width: 100%;
    margin-bottom: 1em;
}

.View-Users h3{
    margin:1em;
    color:green;
}

.user-info{
    background-color: rgb(0, 0, 0, .05);
    color: #000000;

}

.userList th{
    text-align: left;
}

.userList{    
   
    width: 60%;
    margin-bottom: 1em;
}

.userList button, .filters button, .filters select {
    margin: 0 .5em; 
    padding: 0.5rem;
    background-color: #dbba6f;
    text-align: center;
    color:#292929;
    border: none;
    border-radius: .5em;
    height:2em;
    font-size: 1em
}


.userList button:hover, .filters button:hover, .filters select:hover{
    color:#292929;
    background-color: #cbcbcb;
}


.userList th{
    
    color:#dbba6f;
}

.user-info td{
    padding:1em;
}

.user-id{
    text-align: center;
}

.user-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.filters{
    display: flex;
    justify-content: center;
    width:75%;
    margin-bottom: 1em;

}

.filters input{
    width: 20%;
    -webkit-appearance: none;
    border-radius: 0;
    appearance: none;
    font-family: 'Montserrat', sans-serif;
    color: #292929;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: .1em solid #292929;
}

.filters input, .filters button, .filters select{
    margin:0 1em;
}

